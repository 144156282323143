import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';


import './App.css';
import Navbar from './components/Navbar/Navbar.tsx';
import Gallery from './components/Gallery/Gallery.tsx';


const App: React.FC = () => {

  const [,setScrollLocked] = useState(false);

  const toggleScrollLock = (locked: boolean) => {
    setScrollLocked(locked);
    if (locked) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };


  return (
    <section className="App">
      <Router>
          <Navbar />
          <Routes>
            {/* Redirect to /gallery/foto by default */}
            <Route path="/" element={<Navigate to="/foto/fotovse" />} />
            
            {/* Dynamic route for gallery type */}
            <Route path="/:select/:subselect" element={<Gallery toggleScrollLock={toggleScrollLock}/>} />
          </Routes>
      </Router>
      <div className='copyrignt-mobile'>
        <p>© 2025 Tomáš Malcher</p>
      </div>
    </section>
  );
}

export default App;
