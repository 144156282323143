import React from 'react';

import './Gallery.css';

import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import 'ldrs/hourglass'

import listSport from './jsonFiles/foto/listSport.json';
import listStreet from './jsonFiles/foto/listStreet.json';
import listNature from './jsonFiles/foto/listNature.json';
import listOstatni from './jsonFiles/foto/listOstatni.json';

import listDemigod from './jsonFiles/design/listDemigod.json';
import listDOMAX from './jsonFiles/design/listDOMAX.json';
import listCWO from './jsonFiles/design/listCWO.json';

import listl17 from './jsonFiles/kytary/listL17.json';
import listViking from './jsonFiles/kytary/listViking.json';
import listPurple from './jsonFiles/kytary/listPurple7.json';
import listFloyd from './jsonFiles/kytary/listFloyd.json';

import MasonryGallery from '../MasonryGallery/MasonryGallery.tsx';
import Video from '../Video/Video.tsx';
import About from '../About/About.tsx';


interface GalleryProps {
  toggleScrollLock: (locked: boolean) => void;
}



const Gallery: React.FC<GalleryProps> = ({ toggleScrollLock }) => {

  

  const { select, subselect } = useParams<{ select: string; subselect: string }>();

  console.log('Select:', select); // e.g., "foto"
  console.log('Subselect:', subselect); // e.g., "street"



  const listDesign = [...listDemigod, ...listDOMAX, ...listCWO];
  const listKytary = [...listl17, ...listViking, ...listPurple, ...listFloyd];
  const listFoto = [...listSport, ...listStreet, ...listNature, ...listOstatni];


  let ZvoleneFotky = listDesign;

  if (select === 'foto') {
    if (subselect === 'sport') {
      ZvoleneFotky = listSport;
    } else if (subselect === 'street') {
      ZvoleneFotky = listStreet;
    } else if (subselect === 'nature') {
      ZvoleneFotky = listNature;
    } else if (subselect === 'ostatni') {
      ZvoleneFotky = listOstatni;
    } else if (subselect === 'fotovse') {
      ZvoleneFotky = listFoto;
    } else {
      
    }
  } else if (select === 'design') {
    if (subselect === 'demigod') {
      ZvoleneFotky = listDemigod;
    } else if (subselect === 'cwo') {
      ZvoleneFotky = listCWO;
    } else if (subselect === 'domax') {
      ZvoleneFotky = listDOMAX;
    } else if (subselect === 'designvse') {
      ZvoleneFotky = listDesign;
    } else {
      
    }
  } else if (select === 'kytary') {
    if (subselect === 'l17') {
      ZvoleneFotky = listl17;
    } else if (subselect === 'viking') {
      ZvoleneFotky = listViking;
    } else if (subselect === 'floyd') {
      ZvoleneFotky = listFloyd;
    } else if (subselect === 'purple') {
      ZvoleneFotky = listPurple;
    } else if (subselect === 'kytaryvse') {
      ZvoleneFotky = listKytary;
    } else {
      
    }
  } else {
    <p>Invalid Gallery Type</p>;
  }


// Shuffle the original JSON and save to a different variable

  
const [loading, setLoading] = useState(false);
const location = useLocation(); // Detect current URL

useEffect(() => {
  // Trigger loading-active class when the URL changes
  setLoading(true);
  
  window.scrollTo(0, 0);
  const timeout = setTimeout(() => {
    setLoading(false); // Remove the class after 5 seconds
  }, 600);
  
  return () => clearTimeout(timeout); // Cleanup timeout on component unmount
}, [location]); // Dependency array triggers on location change



  return (
    <section className="Gallery">
      {/* 
      <div className='gallery-select-kontrola'>
        {select === 'foto' ? (
          subselect === 'sport' ? (
            <p>Displaying Foto Sport Gallery</p>
          ) : subselect === 'street' ? (
            <p>Displaying Foto Street Gallery</p>
          ) : subselect === 'landscape' ? (
            <p>Displaying Foto Landscape Gallery</p>
          ) : subselect === 'fotovse' ? (
            <p>Displaying Foto All Gallery</p>
          ) : null
        ) : select === 'design' ? (
          subselect === 'demigod' ? (
            <p>Displaying Design Demigod Gallery</p>
          ) : subselect === 'cwo' ? (
            <p>Displaying Design CWO Gallery</p>
          ) : subselect === 'domax' ? (
            <p>Displaying Design DOMAX Gallery</p>
          ) : subselect === 'designvse' ? (
            <p>Displaying Design All Gallery</p>
          ) : null
        ) : select === 'kytary' ? (
          subselect === 'l17' ? (
            <p>Displaying Kytary L17 Gallery</p>
          ) : subselect === 'viking' ? (
            <p>Displaying Kytary Viking Gallery</p>
          ) : subselect === 'purple' ? (
            <p>Displaying Kytary Purple Gallery</p>
          ) : subselect === 'kytaryvse' ? (
            <p>Displaying Kytary All Gallery</p>
          ) : null
        ) : (
          <p>Invalid Gallery Type</p>
        )}
          </div>
        */}  


          <div className={`loader-wrap ${loading ? "loader-active" : ""}`}>
            <div className="loader-container">

            <div className="hourglass-whole"> 
              <div className="hourglass-half"></div>
              <div className="hourglass-half"></div>
            </div>
            </div>
          </div>

          {select === "video" ? (
            <Video />
          ) : select === "omne" ? (
            <div className={`gallery-showing ${loading ? "loading-active" : ""}`}>
              <About />
            </div>
          ) : (
            <div className={`gallery-showing ${loading ? "loading-active" : ""}`}>
              <MasonryGallery photos={ZvoleneFotky} toggleScrollLock={toggleScrollLock}/>
            </div>
          )}



            
          

          
    </section>
  );
}

export default Gallery;