import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { useState} from "react";


import './About.css';


function About(){

        const [copySucc, setCopySucc] = useState(false)
        const CopyToClipboard = ()=>{
          navigator.clipboard.writeText("malchertomm@gmail.com");
          setCopySucc(true)
          setTimeout(() => {
              setCopySucc(false)
            }, 1500);
        }
    return (
        <section className="About">
            <div className="about-textwrap">
                <p className="about-name">
                    Ahoj, jsem <span className="text-highlight">Tomáš</span>.
                </p>
                {/* 
                    <p>Jsem studentem magisterského programu Audio inženýrství na VUT v Brně. Mimo zvuku a hudby mě baví cokoliv kreativního, od mala jsem si hrál s Photoshopem a podobnými programy. V posledních letech jsem v oblasti grafiky získal zkušenosti při kresbě a designu motivů oblečení a nebo například při zajištění grafické stránky největší vzpěračské soutěže na území ČR v roce 2023 - Czech Weightlifting Open.  Kromě grafiky mě baví focení, práce s videem. Velkou část svého volného času věnuji vzpírání a také výrobě elektrických kytar.
                    </p>
                */}
                <p>Jsem student magisterského programu <span className="text-highlight">Audio inženýrství na VUT v Brně</span>, ale moje kreativní zaměření sahá mnohem dál než jen ke zvuku a hudbě. Už od mala jsem si hrál s Photoshopem a dalšími grafickými programy, což postupně přerostlo ve vážnější zájem o <span className="text-highlight">design, focení a práci s videem</span>.</p>

                <p>V posledních letech jsem mimo hudbu a zvuk nasbíral zkušenosti hlavně v oblasti grafiky – tvořil jsem designy na oblečení a měl jsem na starosti grafickou stránku největší vzpěračské soutěže v Česku, <span className="text-highlight">Czech Weightlifting Open 2023 a 2024</span>. Kromě toho se věnuji focení a videoprodukci – baví mě zachycovat atmosféru okamžiku a hledat kreativní úhly pohledu.</p>

                <p>Ve volném čase se hodně věnuji <span className="text-highlight">vzpírání</span> a taky si občas hraju v dílně s <span className="text-highlight">výrobou elektrických kytar</span>. Baví mě objevovat nové věci, posouvat se dál a kreativně se rozvíjet do všech možných směrů.</p>
            </div>
            <div className="about-imgwrap">
                <img src="\assets\img\IMG_8959-Enhanced-NR-3.jpg" alt="Avatar" className="about-img">
                </img>
            </div>
            <div className="about-kontakt">           

                <Tooltip
                  className="mail-copy"
                  title={copySucc ? `Zkopírováno ${'\u2713'}` : "Zkopírovat"}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -80],
                          },
                        },
                      ],
                    },
                  }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#333',
                        color: '#fff',           
                        fontSize: '14px',       
                        borderRadius: '8px',    
                        padding: '8px 12px',   
                        font: "Cormorant Upright"  
                      },
                    },
                  }}
                >
                  
                    
                    <div className="kontakt-item">
                        <p>E-mail:</p>
                        <p className="kontakt-odkaz" onClick={CopyToClipboard}> malchertomm@gmail.com</p>
                    </div>
                </Tooltip>


                    <a className="kontakt-item" 
                        href="/download/CV_Tomas_Malcher.pdf"
                        target="_blank"
                        rel="noopener noreferrer">
                        <p>CV - Životopis</p> 
                    </a>               
                    

                  

                    <div className="kontakt-item">
                        <p>Instagram:</p>
                        <a href="https://www.instagram.com/tommalcher/" target="_blank" rel="noopener noreferrer">
                            <p className="kontakt-odkaz" onClick={CopyToClipboard}> @tommalcher</p>
                        </a>
                    </div>
              </div>
        </section>
    )
} 

export default About;


